'use strict';

const confirmationPopUp = require('../util/confirmationPopUp');
/**
 * Validates an email adres.
 * @param {string} email - The email to validate;
 *
 * @returns {boolean} - Wether or not the email is valid.
 */
function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

module.exports = {
    init: function () {
        $('.js-newsletterSubscribe').on('click', function () {
            const $this = $(this);
            const newsletterEmailInput = $this.parent().find('.js-newsletterEmail');
            const email = $.trim(newsletterEmailInput.val());
            $.spinner().start();

            if (email && email.length > 0 && validateEmail(email)) {
                $.post($this.data('url'), {
                    email: email
                }, function (data) {
                    if (data.result === 'SUCCESS') {
                        confirmationPopUp.show($this.data('success'), confirmationPopUp.TYPE_SUCCESS);
                        $(document).trigger('newsletter:subscribed');
                        $(document).trigger('idTouchPoint:fire', { email_sha256: data.email_sha256, sscid: '', touchpointName: 'newsLetterSubscription' });
                        newsletterEmailInput.val('');
                        $.spinner().stop();
                    } else {
                        confirmationPopUp.show($this.data('failure'), confirmationPopUp.TYPE_ERROR);
                        $.spinner().stop();
                    }
                });
            } else {
                confirmationPopUp.show($this.data('invalid'), confirmationPopUp.TYPE_ERROR);
                $.spinner().stop();
            }
        });

        $('.js-newsletterEmail').on('keypress', function (e) {
            if (e.which === 13) {
                $('.js-newsletterSubscribe').first().trigger('click');
            }
        });
    }
};
