'use strict';

const getHours = (timeString) => {
    if (timeString) {
        const [hours, minutes] = timeString.split(':');

        let openDate = new Date();
        openDate.setHours(hours, minutes);

        return openDate;
    }

    return false;
};

const calculateCurrentlyOpen = () => {
    const $container = $('.js-customerservice-hours');
    if (!$container || !$container.length) return;

    const locale = $('#location-holder').data('locale-language');
    const customerServiceHours = $container.data('hours');
    const exceptionalClosingDays = $container.data('closing-days');
    if (!locale || !customerServiceHours) return;

    const hourObject = customerServiceHours[locale];
    if (!hourObject) return;

    const $indicator = $container.find('.footer-indicator');
    const currentDate = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(currentDate.getDate() + 1);
    let text = '';

    const days = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ];

    let isExceptionallyClosedToday = false;
    let isExceptionallyClosedTomorrow = false;
    if (exceptionalClosingDays) {
        const exceptionalClosingDaysArray = exceptionalClosingDays[locale];
        if (exceptionalClosingDaysArray && exceptionalClosingDaysArray.length) {
            const currentDayFormatted = `${currentDate.getDate()}/${currentDate.getMonth() + 1}`;
            const nextDayFormatted = `${tomorrowDate.getDate()}/${tomorrowDate.getMonth() + 1}`;
            isExceptionallyClosedToday = exceptionalClosingDaysArray.includes(currentDayFormatted);
            isExceptionallyClosedTomorrow = exceptionalClosingDaysArray.includes(nextDayFormatted);
        }
    }

    const day = days[currentDate.getDay()];
    const nextDay = days[(currentDate.getDay() + 1) % 7];

    if (!hourObject[day]) return;

    const openTime = hourObject[day].open;
    const closeTime = hourObject[day].close;

    const tomorrowOpenTime = hourObject[nextDay] ? hourObject[nextDay].open : 'closed';
    const tomorrowCloseTime = hourObject[nextDay] ? hourObject[nextDay].close : 'closed';

    const openHours = getHours(openTime);
    const closedHours = getHours(closeTime);

    const isClosedNow = isExceptionallyClosedToday || openTime === 'closed' || closeTime === 'closed' || currentDate < openHours || currentDate > closedHours;
    const isClosedTomorrow = isExceptionallyClosedTomorrow || tomorrowOpenTime === 'closed' || tomorrowCloseTime === 'closed';
    const willOpenToday = !isExceptionallyClosedToday && openTime !== 'closed' && currentDate < openHours;

    const closedText = $container.data('txt-temp-closed');
    const closedUntilTommorowText = $container.data('txt-closed');
    const closedUntilTodayHourText = $container.data('text-closed-until-today-hour');
    const openText = $container.data('txt-open');

    if (isClosedNow) {
        $indicator.addClass('closed');
        if (willOpenToday) {
            text = `${closedUntilTodayHourText} ${openTime}`;
        } else if (isClosedTomorrow) {
            text = closedText;
        } else {
            text = `${closedUntilTommorowText} ${tomorrowOpenTime}`;
        }
    } else {
        $indicator.addClass('open');
        text = `${openText} ${closeTime}`;
    }

    $('.footer-hours-display').text(text);
    $container.removeClass('d-none');
};

const init = () => {
    calculateCurrentlyOpen();
};

module.exports = {
    init: init
};
